import users from '../reducers/users';
import roles from '../reducers/roles';
import registries from '../reducers/registries';
import bikeModels from '../reducers/bikeModels';
import normalizedModels from '../reducers/normalizedModels';
import ruleDetails from '../reducers/ruleDetails';
import summarizedData from '../reducers/summarizedData';
import reports from '../reducers/reports';
import historials from '../reducers/searchProfiles';

import auth from '../reducers/auth';

import tipos from '../reducers/tipos';
import categorias from '../reducers/categorias';

import ruleProcessingReducer from '../reducers/ruleProcessing';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  auth,
  roles,
  users,
  registries,
  bikeModels,
  normalizedModels,
  ruleDetails,
  summarizedData,
  reports,
  historials,
  tipos,
  categorias,
  ruleProcessingReducer
});

export default rootReducer;

import {
  RETRIEVE_NORMALIZED_MODELS,
  RETRIEVE_NORMALIZED_MODEL,
  UPDATE_NORMALIZED_MODEL,
  CREATE_NORMALIZED_MODEL,
  DELETE_NORMALIZED_MODEL
} from '../actions/types';

const initialState = [];

function normalizedModelReducer(normalizedModels = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_NORMALIZED_MODEL:
      normalizedModels.count++;
      normalizedModels.elements.push(payload.item);
      return normalizedModels;

    case RETRIEVE_NORMALIZED_MODELS:
      return payload;

    case RETRIEVE_NORMALIZED_MODEL:
      return payload;

    case UPDATE_NORMALIZED_MODEL:
      return {
        count: normalizedModels.count,
        elements: normalizedModels.elements.map((model) => {
          if (model._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return model;
          }
        })
      };

    case DELETE_NORMALIZED_MODEL:
      return {
        count: normalizedModels.count - 1,
        elements: normalizedModels.elements.filter(({ id }) => id !== payload._id)
      };
    default:
      return normalizedModels;
  }
}

export default normalizedModelReducer;

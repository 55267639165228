import {
  RETRIEVE_REGISTRIES,
  RETRIEVE_REGISTRY,
  UPDATE_REGISTRY,
  CREATE_REGISTRY,
  DELETE_REGISTRY
} from '../actions/types';

const initialState = [];

function registryReducer(registries = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REGISTRY:
      registries.count++;
      registries.elements.push(payload.item);
      return registries;

    case RETRIEVE_REGISTRIES:
      return payload;

    case RETRIEVE_REGISTRY:
      return payload;

    case UPDATE_REGISTRY:
      return {
        count: registries.count,
        elements: registries.elements.map((reg) => {
          if (reg._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return reg;
          }
        })
      };

    case DELETE_REGISTRY:
      return {
        count: registries.count - 1,
        elements: registries.elements.filter(({ id }) => id !== payload._id)
      };

    default:
      return registries;
  }
}

export default registryReducer;

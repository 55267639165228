import axios from 'axios';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_USERS_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USER_FAILURE';

const API = 'https://back.cafam.org.ar';
//const API = 'https://cafam.acrons.io:3001'
export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT, payload: {} });
    } catch (error) {
      console.log(error);
    }
  };
};

export const login = ({ usuario, password }) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${API}/api/auth/login`, {
        usuario,
        password
      });
      localStorage.setItem('access_token', res.data.token); // Guardar token en el almacenamiento local
      localStorage.setItem('user_id', res.data.user._id); // Guardar el user_id en el almacenamiento local
      dispatch({ type: LOGIN, payload: res.data });
    } catch (error) {
      console.log(error.response.data.detail); // muestra el mensaje de error devuelto por el servidor
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({ type: LOGIN_FAILURE, payload: error.response.data.message });
      } else {
        dispatch({ type: LOGIN_FAILURE, payload: 'Network error' });
      }
      throw error; // Agregar esta línea
    }
  };
};

export const verifyToken = () => async (dispatch) => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    // dispatch({ type: LOGIN, payload: { token: accessToken } });
  } else {
    dispatch({ type: LOGOUT, payload: { token: accessToken } });
    localStorage.removeItem('access_token');
  }
};

export const signIn = ({ name, email, password }) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `${API}/user`,
        data: { name: name, email: email, password: password }
      });
      dispatch({ type: LOGIN, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// usuarios
export const getUsers = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${API}/api/usuarios`);
      dispatch({ type: GET_USERS_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_USERS_FAILURE, payload: error.message });
    }
  };
};

import { GET_FILTERS } from '../actions/types';

const initialState = {
  reports: {
    filters: []
  }
};

function reportsReducer(reports = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FILTERS:
      reports.filters = payload;
      return reports;

    default:
      return reports;
  }
}

export default reportsReducer;

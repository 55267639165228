import axios from 'axios';

export const APPLY_RULES_REQUEST = 'APPLY_RULES_REQUEST';
export const APPLY_RULES_SUCCESS = 'APPLY_RULES_SUCCESS';
export const APPLY_RULES_FAILURE = 'APPLY_RULES_FAILURE';

export const applyRules = (ruleIds, dates) => async (dispatch) => {
  dispatch({ type: APPLY_RULES_REQUEST });

  try {
    const response = await axios.post(
      'https://back.cafam.org.ar/api/patentamientos/applyrules',
      {
        dates,
        ruleIds
      },
      {
        headers: {
          'x-token': `${localStorage.getItem('access_token')}`
        }
      }
    );

    dispatch({ type: APPLY_RULES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: APPLY_RULES_FAILURE, payload: error });
  }
};

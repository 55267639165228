import {
  RETRIEVE_RULE_DETAILS,
  RETRIEVE_RULE_DETAIL,
  UPDATE_RULE_DETAIL,
  CREATE_RULE_DETAIL,
  DELETE_RULE_DETAIL
} from '../actions/types';

const initialState = [];

function ruleDetailReducer(ruleDetails = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_RULE_DETAIL:
      ruleDetails.count++;
      ruleDetails.elements.push(payload.item);
      return ruleDetails;

    case RETRIEVE_RULE_DETAILS:
      return payload;

    case RETRIEVE_RULE_DETAIL:
      return payload;

    case UPDATE_RULE_DETAIL:
      return {
        count: ruleDetails.count,
        elements: ruleDetails.elements.map((rule) => {
          if (rule._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return rule;
          }
        })
      };
    case DELETE_RULE_DETAIL:
      return {
        count: ruleDetails.count - 1,
        elements: ruleDetails.elements.filter((rule) => rule._id !== payload._id)
      };
    default:
      return ruleDetails;
  }
}

export default ruleDetailReducer;

import {
  RETRIEVE_HISTORIALS,
  RETRIEVE_HISTORIAL,
  CREATE_HISTORIAL,
  DELETE_HISTORIAL
} from '../actions/types';

const initialState = { count: 0, elements: [] };

function searchProfileReducer(historials = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_HISTORIAL:
      historials.count++;
      historials.elements.push(payload.item);
      return historials;

    case RETRIEVE_HISTORIALS:
      return payload;

    case RETRIEVE_HISTORIAL:
      return payload;

    case DELETE_HISTORIAL:
      return {
        count: historials.count - 1,
        elements: historials.elements.filter(({ id }) => id !== payload._id)
      };

    default:
      return historials;
  }
}

export default searchProfileReducer;

import {
  GET_DASHBOARD_DATA,
  GET_MONTHLY_BY_BRAND,
  GET_MONTHLY_BY_CATEGORY,
  GET_MONTHLY_BY_CC,
  GET_MONTHLY_BY_PROVINCE,
  GET_COUNTERS,
  GET_FILTERED_COUNTERS,
  GET_TYPES
} from '../actions/types';

const initialState = { cc: [], brand: [], category: [], province: [], counters: {}, types: [] };

function summarizedDataReducer(summarizedData = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARD_DATA:
      summarizedData.brand = payload.brands;
      summarizedData.category = payload.categories;
      summarizedData.cc = payload.cc;
      summarizedData.province = payload.provinces;
      return summarizedData;

    case GET_MONTHLY_BY_BRAND:
      summarizedData.brand = payload;
      return summarizedData;

    case GET_MONTHLY_BY_CATEGORY:
      summarizedData.category = payload;
      return summarizedData;

    case GET_MONTHLY_BY_CC:
      summarizedData.cc = payload;
      return summarizedData;

    case GET_MONTHLY_BY_PROVINCE:
      console.debug(payload);
      summarizedData.province = payload;
      return summarizedData;

    case GET_COUNTERS:
      summarizedData.counters = payload;
      return summarizedData;

    case GET_FILTERED_COUNTERS:
      summarizedData.counters = payload;
      return summarizedData;

    case GET_TYPES:
      summarizedData.types = payload;
      return summarizedData;

    default:
      return summarizedData;
  }
}

export default summarizedDataReducer;

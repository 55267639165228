import {
  APPLY_RULES_REQUEST,
  APPLY_RULES_SUCCESS,
  APPLY_RULES_FAILURE
} from '../actions/ruleProcessing';

const initialState = {
  loading: false,
  data: null,
  error: null
};

const ruleProcessingReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_RULES_REQUEST:
      return { ...state, loading: true, error: null };
    case APPLY_RULES_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case APPLY_RULES_FAILURE:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
};

export default ruleProcessingReducer;

import {
  RETRIEVE_TIPOS,
  RETRIEVE_TIPO,
  UPDATE_TIPO,
  CREATE_TIPO,
  DELETE_TIPO
} from '../actions/types';

const initialState = {
  count: 0,
  elements: []
};

function tipoReducer(tipos = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TIPO:
      tipos.count++;
      tipos.elements.push(payload.item);
      return tipos;

    case RETRIEVE_TIPOS:
      return {
        ...tipos,
        elements: payload
      };

    case RETRIEVE_TIPO:
      return {
        ...tipos,
        elements: payload
      };

    case UPDATE_TIPO:
      return {
        count: tipos.count,
        elements: tipos.elements.map((tipo) => {
          if (tipo._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return tipo;
          }
        })
      };

    case DELETE_TIPO:
      return {
        count: tipos.count - 1,
        elements: tipos.elements.filter(({ _id }) => _id !== payload._id)
      };

    default:
      return tipos;
  }
}

export default tipoReducer;

import {
  RETRIEVE_CATEGORIAS,
  RETRIEVE_CATEGORIA,
  UPDATE_CATEGORIA,
  CREATE_CATEGORIA,
  DELETE_CATEGORIA
} from '../actions/types';

const initialState = {
  count: 0,
  elements: []
};

function categoriaReducer(categorias = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CATEGORIA:
      return {
        ...categorias,
        count: categorias.count + 1,
        elements: [...categorias.elements, payload.item]
      };

    case RETRIEVE_CATEGORIAS:
      return {
        ...categorias,
        elements: payload
      };

    case RETRIEVE_CATEGORIA:
      return {
        ...categorias,
        elements: [payload]
      };

    case UPDATE_CATEGORIA:
      return {
        count: categorias.count,
        elements: categorias.elements.map((item) => {
          if (item._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return item;
          }
        })
      };
    case DELETE_CATEGORIA:
      return {
        ...categorias,
        count: categorias.count - 1,
        elements: categorias.elements.filter(({ id }) => id !== payload.id)
      };

    default:
      return categorias;
  }
}

export default categoriaReducer;

import {
  RETRIEVE_MODELS,
  RETRIEVE_MODEL,
  UPDATE_MODEL,
  CREATE_MODEL,
  DELETE_MODEL
} from '../actions/types';

const initialState = [];

function bikeModelReducer(bikeModels = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_MODEL:
      bikeModels.count++;
      bikeModels.elements.push(payload.item);
      return bikeModels;

    case RETRIEVE_MODELS:
      return payload;

    case RETRIEVE_MODEL:
      return payload;

    case UPDATE_MODEL:
      return {
        count: bikeModels.count,
        elements: bikeModels.elements.map((bike) => {
          if (bike._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return bike;
          }
        })
      };

    case DELETE_MODEL:
      return {
        count: bikeModels.count - 1,
        elements: bikeModels.elements.filter((item) => item._id !== payload.element._id)
      };

    default:
      return bikeModels;
  }
}

export default bikeModelReducer;

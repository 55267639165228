import { LOGIN, LOGOUT } from '../actions/actions';

const initialState = {
  token: '',
  name: '',
  email: '',
  state: false,
  isAdmin: false
};

const checkToken = () => {
  const storedToken = localStorage.getItem('access_token');
  const storedUserRole = localStorage.getItem('user_role');
  if (storedToken && storedUserRole) {
    const isTokenValid = checkTokenExpiration(storedToken);
    if (isTokenValid) {
      const expirationTime = getTokenExpirationTime(storedToken);
      console.debug('Token expira:', expirationTime); // Imprime la hora de expiración en la consola
      return {
        token: storedToken,
        state: true,
        isAdmin: storedUserRole === 'SUPERADMIN' || storedUserRole === 'ADMIN',
        expirationTime: expirationTime
      };
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_role');
      return initialState;
    }
  }

  return initialState;
};

const getTokenExpirationTime = (token) => {
  const tokenParts = token.split('.');
  if (tokenParts.length === 3) {
    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationTime = new Date(payload.exp * 1000); // Multiplica por 1000 para convertir a milisegundos
    return expirationTime;
  }
  return null; // Token inválido
};

const checkTokenExpiration = (token) => {
  const tokenParts = token.split('.');
  if (tokenParts.length === 3) {
    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationTime = payload.exp * 1000; // Multiplica por 1000 para convertir a milisegundos
    const currentTime = Date.now();
    return expirationTime >= currentTime;
  }
  return false; // Token inválido
};

const initialAuthState = checkToken();

export default function auth(state = initialAuthState, action) {
  switch (action.type) {
    case LOGOUT:
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_role');

      return initialState;
    case LOGIN:
      localStorage.setItem('access_token', action.payload.token);
      localStorage.setItem('user_role', action.payload.user.idRol.nombre);
      return {
        token: action.payload.token,
        name: action.payload.user.nombre,
        email: action.payload.user.email,
        state: true,
        isAdmin:
          action.payload.user.idRol.nombre === 'SUPERADMIN' ||
          action.payload.user.idRol.nombre === 'ADMIN'
      };

    default:
      return state;
  }
}

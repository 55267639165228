import {
  RETRIEVE_ROLES,
  RETRIEVE_ROLE,
  UPDATE_ROLE,
  CREATE_ROLE,
  DELETE_ROLE
} from '../actions/types';

const initialState = [];

function roleReducer(roles = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ROLE:
      roles.count++;
      roles.elements.push(payload.item);
      return roles;

    case RETRIEVE_ROLES:
      return payload;

    case RETRIEVE_ROLE:
      return payload;

    case UPDATE_ROLE:
      return {
        count: roles.count,
        elements: roles.elements.map((role) => {
          if (role._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return role;
          }
        })
      };

    case DELETE_ROLE:
      return { count: roles.count - 1, elements: roles.filter(({ id }) => id !== payload._id) };

    default:
      return roles;
  }
}

export default roleReducer;

import {
  RETRIEVE_USERS,
  RETRIEVE_USER,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER
} from '../actions/types';

const initialState = [];

function userReducer(users = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER:
      users.count++;
      users.elements.push(payload.item);
      return users;

    case RETRIEVE_USERS:
      return payload;

    case RETRIEVE_USER:
      return payload;

    case UPDATE_USER:
      return {
        count: users.count,
        elements: users.elements.map((user) => {
          if (user._id === payload._id) {
            return {
              ...payload
            };
          } else {
            return user;
          }
        })
      };

    case DELETE_USER:
      return {
        count: users.count - 1,
        elements: users.elements.filter(({ id }) => id !== payload._id)
      };

    default:
      return users;
  }
}

export default userReducer;

// Roles
export const RETRIEVE_ROLES = 'RETRIEVE_ROLES';
export const RETRIEVE_ROLE = 'RETRIEVE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

// Users
export const RETRIEVE_USERS = 'RETRIEVE_USERS';
export const RETRIEVE_USER = 'RETRIEVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';

// Registries
export const RETRIEVE_REGISTRIES = 'RETRIEVE_REGISTRIES';
export const RETRIEVE_REGISTRY = 'RETRIEVE_REGISTRY';
export const UPDATE_REGISTRY = 'UPDATE_REGISTRY';
export const CREATE_REGISTRY = 'CREATE_REGISTRY';
export const DELETE_REGISTRY = 'DELETE_REGISTRY';

// Bike Models
export const RETRIEVE_MODELS = 'RETRIEVE_MODELS';
export const RETRIEVE_MODEL = 'RETRIEVE_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const CREATE_MODEL = 'CREATE_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';

// Normalized models
export const RETRIEVE_NORMALIZED_MODELS = 'RETRIEVE_NORMALIZED_MODELS';
export const RETRIEVE_NORMALIZED_MODEL = 'RETRIEVE_NORMALIZED_MODEL';
export const UPDATE_NORMALIZED_MODEL = 'UPDATE_NORMALIZED_MODEL';
export const CREATE_NORMALIZED_MODEL = 'CREATE_NORMALIZED_MODEL';
export const DELETE_NORMALIZED_MODEL = 'DELETE_NORMALIZED_MODEL';

// Rule details
export const RETRIEVE_RULE_DETAILS = 'RETRIEVE_RULE_DETAILS';
export const RETRIEVE_RULE_DETAIL = 'RETRIEVE_RULE_DETAIL';
export const UPDATE_RULE_DETAIL = 'UPDATE_RULE_DETAIL';
export const CREATE_RULE_DETAIL = 'CREATE_RULE_DETAIL';
export const DELETE_RULE_DETAIL = 'DELETE_RULE_DETAIL';

// Summarized data
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_MONTHLY_BY_BRAND = 'GET_MONTHLY_BY_BRAND';
export const GET_MONTHLY_BY_PROVINCE = 'GET_MONTHLY_BY_PROVINCE';
export const GET_MONTHLY_BY_CC = 'GET_MONTHLY_BY_CC';
export const GET_MONTHLY_BY_CATEGORY = 'GET_MONTHLY_BY_CATEGORY';
export const GET_COUNTERS = 'GET_COUNTERS';
export const GET_FILTERED_COUNTERS = 'GET_FILTERED_COUNTERS';
export const GET_TYPES = 'GET_TYPES';

// Reports
export const GET_FILTERS = 'GET_FILTERS';

// Historial
export const RETRIEVE_HISTORIALS = 'RETRIEVE_HISTORIALS';
export const RETRIEVE_HISTORIAL = 'RETRIEVE_HISTORIAL';
export const CREATE_HISTORIAL = 'CREATE_HISTORIAL';
export const DELETE_HISTORIAL = 'DELETE_HISTORIAL';

// TIPOS
export const RETRIEVE_TIPOS = 'RETRIEVE_TIPOS';
export const RETRIEVE_TIPO = 'RETRIEVE_TIPO';
export const UPDATE_TIPO = 'UPDATE_TIPO';
export const CREATE_TIPO = 'CREATE_TIPO';
export const DELETE_TIPO = 'DELETE_TIPO';

// Categorias
export const RETRIEVE_CATEGORIAS = 'RETRIEVE_CATEGORIAS';
export const RETRIEVE_CATEGORIA = 'RETRIEVE_CATEGORIA';
export const UPDATE_CATEGORIA = 'UPDATE_CATEGORIA';
export const CREATE_CATEGORIA = 'CREATE_CATEGORIA';
export const DELETE_CATEGORIA = 'DELETE_CATEGORIA';

import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { verifyToken } from '../redux/actions/actions';

export default function PrivateRoute() {
  const isLogin = useSelector((store) => store.auth.state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  return isLogin ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} replace />;
}
